<script setup lang="ts">
const {
   collapse,
   expand,
   isCollapsed,
   isExpanded,
} = usePanelState()

/**
 * Translation.
 */
const { t } = useI18n()
</script>

<template>
   <div class="grid grid-cols-2 text-xl drop-shadow">
      <UiButton
         :class="{ invisible: isCollapsed }"
         @click="collapse"
      >
         <Icon
            name="heroicons-solid:chevron-left"
         />
         <span class="sr-only">{{ t('global.collapse') }}</span>
      </UiButton>

      <UiButton
         :class="{ hidden: isExpanded }"
         @click="expand"
      >
         <span class="sr-only">{{ t('global.expand') }}</span>
         <Icon
            name="heroicons-solid:chevron-right"
         />
      </UiButton>
   </div>
</template>
