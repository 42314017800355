<template>
   <div class="flex flex-col overflow-hidden">
      <div class="relative flex-1 overflow-hidden">
         <MapView class="size-full" />
         <div class="pointer-events-none absolute bottom-0 left-0 flex flex-col gap-2 lg:gap-4">
            <MapTools class="pointer-events-auto mx-2 ml-auto lg:mx-4" />
            <MapDisclaimer />
         </div>
      </div>
      <MapOptions class="w-full" />
   </div>
</template>
