<script setup lang="ts">
/**
 * Use map position.
 */
const { setPosition } = useMapPosition()

/**
 * Use geolocation.
 */
const {
   coords,
   error,
   isSupported,
   pause,
   resume,
} = useGeolocation({ immediate: false })

/**
 * Loading state.
 */
const loading = ref(false)

/**
 * Set the map position to the current geolocation.
 */
function updateCoords(coords: GeolocationCoordinates) {
   if (Number.isFinite(coords.longitude) && Number.isFinite(coords.latitude)) {
      setPosition({
         lat: coords.latitude,
         lng: coords.longitude,
         zoom: 10,
      })
   }
}

/**
 * Set the map position to the current geolocation
 */
function setMapPosition() {
   resume()

   loading.value = true

   watchOnce(coords, (coords) => {
      if (error.value)
         return console.error(error.value)
      updateCoords(coords)
      loading.value = false
      pause()
   })
}

const { t } = useI18n()
</script>

<template>
   <button
      v-show="isSupported"
      @click="setMapPosition"
   >
      <Icon
         :class="[
            loading ? 'animate-spin' : '',
         ]"
         :name="loading ? 'heroicons-outline:refresh' : 'heroicons-outline:map-pin'"
      />
      <span class="sr-only">{{ t('global.geolocation') }}</span>
   </button>
</template>
