import { joinURL } from 'ufo'
import { defu } from 'defu'

/**
 * Use map layers.
 */
export async function useMapLayers() {
   /**
    * Current route.
    */
   const route = useRoute()

   /**
    * Feature id.
    */
   const featureId = ref(route.params.id?.toString())

   /**
    * Fetch layers.
    */
   const { data: layers } = await useCachedFetch('/layers', {
      default: () => [],
      key: 'layers',
      transform: layers => layers.map(toFeatureLayer),
   })
   /**
    * Main layers.
    */
   const mainLayers = computed(() => {
      return (toValue(layers).filter(layer => !layer.isExtra))
   })

   /**
    * Extra layers.
    */
   const extraLayers = computed(() => {
      return (toValue(layers).filter(layer => layer.isExtra))
   })

   /**
    * Convert to feature layer.
    */
   function toFeatureLayer(obj: __esri.FeatureLayerProperties) {
      return {
         ...obj,
         outFields: [obj.idKey],
         renderer: defu(obj.renderer, {
            symbol: {
               color: {
                  a: 0.25,
               },
               outline: {
                  style: 'none',
               },
               style: 'solid',
               type: 'simple-fill',
            },
            type: 'simple',
         }),
         url: joinURL('/layers', obj.id || ''),
      } satisfies __esri.FeatureLayerProperties
   }

   /**
    * Main layer displayed.
    */
   const mainLayerDisplayed = useQueryParam('layer', { defaultValue: () => toValue(mainLayers)[0]?.id })

   /**
    * Extra layers displayed.
    */
   const extraLayersDisplayed = useQueryParam('extra', { defaultValue: () => [] as string[] })

   watch(() => route.params.id?.toString(), (id) => {
      featureId.value = id
   })

   return {
      extraLayers,
      extraLayersDisplayed,
      featureId,
      layers,
      mainLayerDisplayed,
      mainLayers,
   }
}
