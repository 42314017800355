<script setup lang="ts">
import { CheckboxIndicator, CheckboxRoot } from 'radix-vue'

export interface Props {
   label: string
   value: string
}

const { label, value } = defineProps<Props>()

const modelValue = defineModel<unknown[]>()

/**
 * Update the model value.
 */
function onUpdate(checked: boolean) {
   const values = toValue(modelValue) as string[]

   if (checked) {
      modelValue.value = values.concat(value)
   }
   else {
      const index = values.indexOf(value)
      modelValue.value = values.toSpliced(index, 1)
   }
}

/**
 * Whether the checkbox is checked.
 */
const isChecked = computed(() => {
   return toValue(modelValue)?.includes(value)
})
</script>

<template>
   <label class="flex items-center gap-2">
      <CheckboxRoot
         :checked="isChecked"
         :value="value"
         type="checkbox"
         class="size-6 rounded border bg-white p-1"
         :aria-label="label"
         @update:checked="onUpdate"
      >
         <CheckboxIndicator class="grid size-full place-items-center">
            <Icon
               class="text-link"
               name="heroicons-solid:check"
            />
         </CheckboxIndicator>
      </CheckboxRoot>
      <span>{{ label }}</span>
   </label>
</template>
