<script setup lang="ts">
import { TooltipProvider } from 'radix-vue'

const {
   isCollapsed,
   isExpanded,
} = usePanelState()

const { isLoading } = useLoadingIndicator()
</script>

<template>
   <div class="flex h-dvh flex-col overflow-hidden bg-gray-100">
      <AppNav />
      <TooltipProvider>
         <main class="relative flex-1 flex-row-reverse overflow-hidden max-md:overflow-y-auto md:flex">
            <Map
               class="sticky top-0 max-md:h-3/4 md:flex-1"
               @touchmove.prevent
            />
            <div
               class="relative transition-[width] duration-300 ease-linear md:max-w-4xl md:overflow-y-visible"
               :class="{
                  'md:w-1/2': isExpanded,
                  'md:w-1/4': isCollapsed,
               }"
            >
               <Panel>
                  <NuxtPage />
               </Panel>

               <div
                  v-if="isLoading"
                  class="absolute inset-0 z-[1] bg-white bg-opacity-90"
               >
                  <AppLoader />
               </div>
            </div>
         </main>
      </TooltipProvider>
   </div>
</template>
