<script setup lang="ts">
const { zoom } = useMapPosition()

const { t } = useI18n()
</script>

<template>
   <button @click="zoom++">
      <Icon name="heroicons-outline:plus" />
      <span class="sr-only">{{ t('global.zoom-in') }}</span>
   </button>
   <button @click="zoom--">
      <Icon name="heroicons-outline:minus" />
      <span class="sr-only">{{ t('global.zoom-out') }}</span>
   </button>
</template>
