/**
 * This composable is used to manage the state of the panel.
 */
export const usePanelState = createSharedComposable(() => {
   const state = useQueryParam('panel', { defaultValue: () => 'expanded' as 'expanded' | 'collapsed' })

   const isExpanded = computed(() => {
      return toValue(state) === 'expanded'
   })

   const isCollapsed = computed(() => {
      return toValue(state) === 'collapsed'
   })

   function expand() {
      state.value = 'expanded'
   }

   function collapse() {
      state.value = 'collapsed'
   }

   return {
      collapse,
      expand,
      isCollapsed,
      isExpanded,
   }
})
