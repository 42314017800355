<script setup lang="ts">
export interface Props {
   vertical?: boolean
   options: {
      label: string
      value: string
   }[]
}

const { options, vertical } = defineProps<Props>()

const value = defineModel<unknown[]>()
</script>

<template>
   <div
      class="flex flex-wrap gap-3"
      :class="{
         'flex-col items-start': vertical,
         'items-center': !vertical,
      }"
   >
      <UiInputCheckbox
         v-for="(option, index) in options"
         :key="index"
         v-model="value"
         :label="option.label"
         :value="option.value"
      />
   </div>
</template>
