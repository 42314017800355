/**
 * Map position composable.
 */
export const useMapPosition = createSharedComposable(() => {
   /**
    * Current route.
    */
   const route = useRoute()

   /**
    * Map longitude.
    */
   const longitude = useQueryParam('lng', {
      defaultValue: () => 45.9441973,
   })

   /**
    * Map latitude.
    */
   const latitude = useQueryParam('lat', {
      defaultValue: () => 49.9555081,
   })

   /**
    * Map zoom.
    */
   const zoom = useQueryParam('zoom', { defaultValue: () => 3 })

   /**
    * Set the map position.
    */
   function setPosition(position: Position) {
      const query = {
         ...route.query,
         lat: position.lat.toString(),
         lng: position.lng.toString(),
         zoom: position.zoom.toString(),
      }

      navigateTo({ query }, { replace: true })
   }

   return {
      latitude,
      longitude,
      setPosition,
      zoom,
   }
})

interface Position {
   lat: number
   lng: number
   zoom: number
}
