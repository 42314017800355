<script setup lang="ts">
/**
 * Use map layers.
 */
const { mainLayerDisplayed, mainLayers } = await useMapLayers()

/**
 * Translations.
 */
const { t } = useI18n()

/**
 * Options for the radio group.
 */
const options = computed(() => {
   return toValue(mainLayers).map(layer => ({
      label: t(`layers.${layer.id}`),
      value: layer.id || '',
   }))
})
</script>

<template>
   <UiInputRadioGroup
      v-model="mainLayerDisplayed"
      vertical
      :options="options"
   />
</template>
