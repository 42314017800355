<script setup lang="ts">
/**
 * Use map layers.
 */
const { extraLayers, extraLayersDisplayed } = await useMapLayers()

/**
 * Translations.
 */
const { t } = useI18n()

/**
 * Options for the radio group.
 */
const options = computed(() => {
   return toValue(extraLayers).map(layer => ({
      label: t(`layers.${layer.id}`),
      value: layer.id || '',
   }))
})
</script>

<template>
   <UiInputCheckboxGroup
      v-model="extraLayersDisplayed"
      vertical
      :options="options"
   />
</template>
