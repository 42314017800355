<script setup lang="ts">
import { RadioGroupRoot } from 'radix-vue'

export interface Props {
   vertical?: boolean
   options: {
      label: string
      value: string
   }[]
}

const { options, vertical } = defineProps<Props>()

const value = defineModel<string>()
</script>

<template>
   <RadioGroupRoot
      v-model="value"
      class="flex  flex-wrap gap-3"
      :class="{
         'flex-col items-start': vertical,
         'items-center': !vertical,
      }"
   >
      <UiInputRadio
         v-for="(option, index) in options"
         :key="index"
         :label="option.label"
         :value="option.value"
      />
   </RadioGroupRoot>
</template>
